<template>
  <main class="tourism-product page_bg_grey">
    <TourismReport :reportDetails="reportDetails" :loading="loading"></TourismReport>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TourismReport from "./../../components/tourism/TourismReport.vue"
export default {
  name: "ReportDetails",
  components: {
    Loading,
    TourismReport
  },
  computed: {
    ...mapState({
      loading: (state) => state.report_details.loading,
      reportDetails: (state) => state.report_details.reportDetails
    }),
  },
  async created() {
    const transaction_id = this.$route.params.transaction_id;
    const phoneNumber = this.$route.query.phoneNumber;
    const args = {
      transaction_id: transaction_id,
      phoneNumber: phoneNumber,
    };
    await this.getReportDetails({ args: args });
  },
  methods: {
    ...mapActions("report_details", {
      getReportDetails: "getReportDetails",
    }),
  },
};
</script>

